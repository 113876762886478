import PropTypes from 'prop-types';
import React from 'react';

export const HTMLContent = ({ content, containerClasses }) => (
  // eslint-disable-next-line react/no-danger
  <div className={ `${containerClasses} markdown-container` } dangerouslySetInnerHTML={{ __html: content }} />
);

const MarkdownContent = ({ content, containerClasses }) => (
  <div className={ `${containerClasses} markdown-container` }>{content}</div>
);

MarkdownContent.propTypes = {
  content: PropTypes.node.isRequired,
  containerClasses: PropTypes.string,
};

MarkdownContent.defaultProps = {
  containerClasses: '',
};

HTMLContent.propTypes = MarkdownContent.propTypes;

export default MarkdownContent;